import { TYPE_BROADCAST } from './types'
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT]: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}* 

Mohon klik link website announcement kami dibawah ini sebagai detail pemberitahuan:
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami dan tanpa mengurangi rasa hormat, kami berniat ingin mengumumkan kepada Bapak/Ibu/Saudara/i mengenai acara pernikahan kami: 

*{{BOY_NAME}}*
{{BOY_FATHER_NAME_ID}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}* 
{{GIRL_FATHER_NAME_ID}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

Berkaitan dengan salah satu pemberlakuan protokol kesehatan, yakni pembatasan jumlah tamu, kami memohon maaf jika belum bisa mengundang Bapak/Ibu/Saudara/i untuk hadir secara langsung. 

Meski demikian, di masa pandemi saat ini hal terpenting adalah sama-sama saling menjaga agar kita semua selalu sehat dan senantiasa dalam lindungan Tuhan.

Terima kasih atas segala doa restu yang telah diberikan kepada kami. 
Mohon pengertiannya, terima kasih.

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*`,

  [TYPE_BROADCAST.CUSTOM_INVITATION]: `Yth. Bapak/Ibu/Saudara/i,
*{{GUEST_NAME}}*

Dengan segala kerendahan hati, kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dalam acara pernikahan kami:

*Andre Evan*
_Putra dari Bapak Then Po Lin dan Ibu Bong Mui Khim_
&
*Claudia Sergio,*
_Putri dari Bapak Tjong Hon Liong dan Ibu Mimi Halim_

Acara akan diselenggarakan pada:
Hari: Sabtu, 02 Desember 2023
Waktu: 6.30 PM
Lokasi: Doubletree by Hilton Hotel Jakarta (Makara Ballroom)

*Tanpa mengurangi rasa hormat, berikut link undangan website kami:*
{{LINK}}
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan simpan nomor ini untuk dapat membuka link._

Kehadiran Bapak/Ibu/Saudara/i tentu akan menjadi kehormatan besar bagi kami dan keluarga. Mohon sampaikan konfirmasi kehadiran Anda dengan cara mengisi konfirmasi kehadiran (RSVP) yang sudah tersedia di dalam undangan website kami. Semoga kita semua dapat segera bertemu dalam keadaan sehat.

Terima kasih.

*Kami yang berbahagia,*
_Andre, Claudia & keluarga_`,

  //Reminder RSVP

    [TYPE_BROADCAST.ID_REMINDER_RSVP] : `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melanjutkan undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, kami ingin mengingatkan Bapak/Ibu/Saudara/i untuk segera mengisi formulir konfirmasi kehadiran (RSVP) yang ada pada website undangan kami:
{{LINK}} 

Status kehadiran Bapak/Ibu/Saudara/i akan bermanfaat untuk kami dalam mempersiapkan acara pernikahan mendatang. 

Terima kasih atas perhatiannya.

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_`,

[TYPE_BROADCAST.EN_REMINDER_RSVP]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Following up on our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, we would like to remind you to immediately fill out the attendance confirmation form (RSVP) on our invitation website: 
{{LINK}}

Your attendance status will be very useful for us in preparing the upcoming wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_`,

[TYPE_BROADCAST.ID_REMINDER_EVENT]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melalui pesan ini, kami ingin mengingatkan kembali undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* yang diselenggarakan pada: 

Hari: {{BROADCAST_WEDDING_DAY}}
Waktu: {{TIME}} 
Lokasi: {{BROADCAST_WEDDING_LOCATION}}

Besar harapan kami Bapak/Ibu/Saudara/i berkenan menghadiri pernikahan kami.

Terima kasih 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_`,

[TYPE_BROADCAST.EN_REMINDER_EVENT]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Through this message, we would like to remind you of our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}* which will be held on:

Date: {{BROADCAST_WEDDING_DAY}}
Time: {{TIME}}
Place: {{BROADCAST_WEDDING_LOCATION}}

We hope that you are willing to attend our wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_`,

[TYPE_BROADCAST.ID_THANKS]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* dan keluarga ingin mengucapkan terima kasih atas kesediaan Bapak/Ibu/Saudara/i untuk hadir dalam acara pernikahan kami. 

Kehadiran Bapak/Ibu/Saudara/i tentunya telah menjadi kebahagiaan untuk kami & keluarga. Kami juga ingin memohon maaf jika selama berlangsungnya acara terdapat hal-hal yang kurang berkenan. 
 
Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_`,

[TYPE_BROADCAST.EN_THANKS]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

We, *{{BOY_NAME}}* & *{{GIRL_NAME}}* and family would like to thank you for your willingness to attend our wedding.

Your presence has certainly been a joy for us & our family. We also want to apologize if during the event there are things that are not pleasing.
 
Thank you for all the words, prayers, and attention given.

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_`,
}